import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";
import icon from '../../../../assets/languages/en.svg'

export const enLang: LangPack = {
    lang: Lang.EN,
    langName: "English",
    langShort: "en",
    langIconUrl: icon,
    direction: "ltr",
    pack: {
        common: {
            noBTN: "No",
            yesBTN: "Yes",
            errorCodeTitle: "Error code",
            pageSizeSwitcherLabel: "Rows on page" ,
            ageConfirmationMessage: "Do you confirm that you are 18 years old?",
            iConfirmBTN: "I confirm",
            viewAllBTN: "View all",
            searchPlaceholder: "Search",
            loadMore: "Load more"
        },
        internalErrorMessages: {
            networkError: "A network error occurred while executing the request.",
            error502: "A 502 server error occurred while executing the request."
        },
        errorMessages: {
            2: "No entries.",
            3: "User email address already existing.",
            4: "User phone number already existing.",
            5: "User already logged in.",
            6: "Wrong password.",
            7: "Empty games result.",
            8: "Result ID does not match the request id.",
            9: "Outcome request error.",
            10: "Outcome no session Id in the response.",
            11: "User already existing.",
            12: "Your token is not valid. Login again.",
            13: "You have not enough rights to perform this action.",
            14: "Userid for for this action missing.",
            15: "Money amount missing.",
            16: "Action does not exist.",
            17: "Token missing.",
            18: "Not enough money.",
            19: "You must be the parent of this user to perform this action.",
            20: "User does not exist.",
            21: "The value type for this field is wrong.",
            22: "Password missing.",
            23: "User name missing.",
            24: "Gameid missing.",
            25: "New password missing.",
            26: "Token could not be set up.",
            27: "Token could not be deleted.",
            28: "Userid missing.",
            29: "The value type for this field should be datetime in ISO.",
            30: "Could not update user ban.",
            31: "This user must be in your tree to perform this action.",
            32: "This user is banned and cannot perform any actions.",
            33: "Currency missing.",
            34: "Maximum value missing.",
            35: "Limit type must be once, daily, weekly or monthly.",
            36: "Limit type missing.",
            37: "Provider does not exist.",
            38: "Tier condition amount missing.",
            39: "Tier condition type missing.",
            40: "Tier title missing.",
            41: "Cashback for this tier is already existing.",
            42: "Cashback for this tier not existing.",
            43: "Bonus for this promocode is already existing.",
            44: "Bonus does not exist.",
            45: "Promocode missing.",
            46: "Start time missing.",
            47: "Deposit amount condition missing.",
            48: "Wager missing.",
            49: "Amount validity days missing.",
            50: "Id missing.",
            51: "Cashback type missing, must be be \"wager\" or \"deposit\".",
            52: "Cashback rate missing.",
            53: "This currency is not available.",
            54: "Ban parameter missing - true or false.",
            55: "The bank group for this currency is not valid.",
            56: "Payment method name missing.",
            57: "This user already added an account for this payment system.",
            58: "This payment method does not exist.",
            59: "This payment method does not support the currency of this user.",
            60: "This user does not have an existing account for this payment system.",
            61: "The parent of this user does not have an existing account for this payment system.",
            62: "Transfer id missing.",
            63: "Transfer with this id does not exist.",
            64: "This transfer is requested by a different parent.",
            65: "You still have a bonus which is not expired.",
            66: "This bonus promo action has not started yet.",
            67: "This bonus promo action is already over.",
            68: "This deposit does not fit the bonus requirements.",
            69: "This transfer is not pending and cannot be approved or declined.",
            70: "Approve flag (true/false) missing.",
            71: "Wrong week day for this bonus.",
            72: "The number of your deposits does not fit the bonus requirements.",
            73: "Mandatory parameters for this payment method missing or do not match the proof format.",
            74: "Deposit rate missing.",
            75: "User role missing.",
            76: "You cannot create a user with this user role.",
            77: "Fun active parameter missing.",
            78: "This function is not available for this website.",
            79: "Coins amount missing.",
            80: "Price for coins pack missing.",
            81: "Provider type is missing.",
            82: "Outcome request error.",
            83: "Provider name missing.",
            84: "Contractor name missing.",
            85: "Contractor does not exist.",
            86: "Goodies pack missing.",
            87: "Proof params missing.",
            88: "Goodies pack does not exist.",
            89: "This user cannot create agents.",
            90: "This user is disabled for depositing.",
            91: "This user is disabled for withdrawals.",
            92: "Transaction type(s) missing.",
            93: "No users found.",
            94: "Rangs missing.",
            95: "No provider with this rang.",
            96: "Cannot apply this action to providers with different types.",
            97: "No game with this rang.",
            98: "Cannot apply this action to games with different providers.",
            99: "Cannot apply this action to games with different contractors.",
            100: "User name can contain only latin letters, numbers, \".\" and \"_\" and have a length between 5 and 18 characters.",
            101: "User password can contain only latin letters, numbers, the following special charachters \"_\", \".\", \"?\", \"!\" and have a length between 5 and 18 characters.",
            102: "Wager and/or deposit condition must be greater than 0.",
            103: "This game does not exist or is switched off.",
            104: "Gitslotpark request error.",
            105: "Fun balance could not be switched.",
            106: "Money transfer entry could not be saved in the database.",
            107: "You can create users only for yourself or your agents.",
            108: "Error creating Evolution token.",
            109: "Parent and child userroles do not allow depositing.",
            110: "Parent and child userroles do not allow withdrawing.",
            111: "Could not create Ezugi token.",
            112: "Could not create Relum token.",
            113: "Cool down time for the wheel has not run out.",
            114: "Freepack not available (yet).",
            115: "Could not create Amigo token."
        },
        userInfoForm: {
            emailIsRequiredMess: 'Email is required',
            notValidEmail: 'Enter a valid email address',
            currentPassFieldLabel: "Current password",
            emailFieldLabel: "Email",
            emptyFieldWarningMessage: "This field must not be empty",
            firstnameFieldLabel: "Firstname",
            lastnameFieldLabel: "Lastname",
            newPassFieldLabel: "New password",
            passNotMatchWarningMessage: "New password and retype password do not match",
            phoneFieldLabel: "Phone",
            retypePassFieldLabel: "Retype new password",
            saveInfoBTN: "Save info",
            updatePassBTN: "Update password",
            usernameLabel: "Username"
        },
        userInfo: {
            userInfoSection: 'User info',
            changePasswordSection: 'Change password'
        },
        transactionsTable: {
            amountTableHeader: "Amount",
            methodTableHeader: "Method",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Deposit/Withdraw",
            dateTimeTableHeader: "Date/Time"
        },
        gamesHistory: {
            providerTableHeader: 'Provider',
            gameTableHeader: 'Game',
            betAmountTableHeader: 'Bet amount',
            winAmountTableHeader: 'Win amount',
            dateTimeTableHeader: 'Date/Time'
        },
        authorization: {
            loginTitle: 'Login',
            loginBTN: 'Login',
            usernameLabel: 'Username',
            passwordLabel: 'Password',
            confirmLogOutMessage: 'Are you sure you want to log out?',
        },
        navigation: {
            home: 'Home',
            sports: 'Sports',
            liveSports: 'Live Sports',
            casino: 'Casino',
            liveCasino: 'Live Casino',
            virtuals: 'Virtuals',
            userInfo: 'User info',
            transactions: 'Transactions',
            gamesHistory: 'Games history'
        },
        footer: {
            playResponsibly: 'Play Responsibly',
            ageRestriction: 'In order to register for this website, the user is required to accept the General Terms and Conditions. In the event the General Terms and Conditions are updated, existing users may choose to discontinue using the products and services before the said update shall become effective, which is a minimum of two weeks after it has been announced.',
            disclaimer: 'All www.uibet.org products are operated by uibet. Maximum winnings are 100.000 TND for all products in a month, terms and conditions apply.',
            footerColumns: {
                casino: {
                    title: 'Casino',
                    featured: 'Featured',
                    new: 'New',
                    slots: 'Slots',
                    crash: 'Crash',
                    pragmatic: 'Pragmatic',
                    providers: 'Providers',
                    gameShows: 'Game shows',
                    roulette: 'Roulette',
                    blackjack: 'Blackjack',
                    tableGames: 'Table games',
                    otherGames: 'Other games'
                },
                sports: {
                    title: 'Sports',
                    preliveEvents: 'Prelive Events',
                    liveEvents: 'Live Events',
                    football: 'Football',
                    basketball: 'Basketball',
                    tennis: 'Tennis'
                },
                legal: {
                    title: 'Legal',
                    termsNConditions: 'Terms & Conditions',
                    privacyPolicy: 'Privacy Policy',
                    responsibleGaming: 'Responsible Gaming'
                },
                help: {
                    title: 'Help',
                    aboutUs: 'About Us'
                },
                contactUs: {
                    title: 'Contact Us',
                }
            }
        },
        topLinks: {
            sports: 'Sports',
            liveSports: 'Live Sports',
            casino: 'Casino',
            liveCasino: 'Live Casino',
            pragmatic: 'Pragmatic',
            evolution: 'Evolution',
        },
        subcategories: {
            featured: 'Featured',
            new: 'New',
            slots: 'Slots',
            crash: 'Crash',
            pragmatic: 'Pragmatic',
            providers: 'Providers',
            liveCasino: 'Live Casino',
            gameShows: 'Game shows',
            roulette: 'Roulette',
            blackjack: 'Blackjack',
            tableGames: 'Table games',
            otherGames: 'Other games',
            virtuals: 'Virtuals',
            unknownGameType: 'Unknown game type',
            provider: 'Provider',
        },
        casino: {
            showedOf: 'Showed %%a%% of %%b%%',
        },
        searchWindow: {
            title: 'Search engine',
            searchPlaceholder: 'Type to Search (Game | Provider)',
            searchHelper: 'Search requires at least 3 characters',
            foundProviders: 'Providers',
            foundGames: 'Games'
        },
        cashback: {
            title: 'Cashback is yours!',
            gotItBTN: 'Got it',
        }
    }
}