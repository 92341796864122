import React, {FC, useEffect, useState} from "react";
import {GameFrame} from "../components/game_launcher/GameFrame";
import {LoginFormModal} from "../components/authorization/login/LoginForm";
import {Page} from "../components/layout/Page";
import {setGameSession} from "../features/play_games/playGamesSlice";
import {usePlayGames} from "../features/play_games/usePlayGames";
import {useUser} from "../features/user/useUser";
import {useAppDispatch} from "../store";
import {Box, Stack} from "@mui/material";

export const VirtualesPage: FC = () => {
    const [justMounted, setJustMounted] = useState<boolean>(true)
    const { startGameSession, gameSession } = usePlayGames()
    const {user} = useUser()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (justMounted) {
            dispatch(setGameSession({SessionId: null, SessionUrl: null}))
            setJustMounted(false)
            if (gameSession.SessionUrl === null) {
                startGameSession(5437)
            }
        }
    }, [dispatch, gameSession.SessionUrl, justMounted, startGameSession]);

    return <Page>
        <Stack>
            <Box
                width={'100%'}
                height={'85dvh'}
            >
                <GameFrame/>
            </Box>
        </Stack>

        {user === null && <LoginFormModal/>}
    </Page>
}