import {Box, Divider, Stack, Typography, useTheme} from "@mui/material"
import {FC} from "react"
import {FooterInfoBlock} from "./FooterInfoBlock";
import {ProvidersSection} from "./ProvidersSection";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {LanguageSwitcherMobile} from "../../language_switcher/LanguageSwitcherMobile";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const Footer: FC = () => {
    const {languagePack: {pack: {footer: lang}}} = useLanguage()
    const theme = useTheme()
    const {mediumScreen: md} = useCommonMediaQueries()

    return <Box
        component={'footer'}
        display={'flex'}
        justifyContent={'center'}
        p={theme.spacing(2, 1)}
    >
        <Stack
            gap={3}
            maxWidth={'1280px'}
            width={'100%'}
        >
            {md && <LanguageSwitcherMobile/>}
            <FooterInfoBlock/>
            <Divider/>
            <ProvidersSection/>
            <Divider/>
            {/*<Box*/}
            {/*    display={'flex'}*/}
            {/*    justifyContent={'center'}*/}
            {/*    sx={theme => ({*/}
            {/*        '& a': {*/}
            {/*            textDecoration: 'none',*/}
            {/*            color: theme.palette.text.primary },*/}
            {/*    })}*/}
            {/*>*/}
            {/*    <Link*/}
            {/*        to={'https://www.facebook.com/people/Silverbet365/61566828065243/'}*/}
            {/*        target={'_blank'}*/}
            {/*    >*/}
            {/*        <FacebookIcon sx={{fontSize: '40px'}}/>*/}
            {/*    </Link>*/}
            {/*</Box>*/}


                <Typography textAlign={'center'} fontSize={'14px'} lineHeight={'21px'}>
                    {lang.disclaimer}
                </Typography>

        </Stack>
    </Box>
}