import Config from "../../config";


const CASHBACK = Config.localStoragePrefix + 'cashback'

class CashbackStorage {
    static getCashback () : number | null {
        const s = localStorage.getItem(CASHBACK)
        if (s !== null) return JSON.parse(s) as number
        return null
    }

    static setCashback (cashback: number) {
        localStorage.setItem(CASHBACK, JSON.stringify(cashback))
    }

    static removeCashback () {
        localStorage.removeItem(CASHBACK)
    }
}

export default CashbackStorage