import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";
import icon from '../../../../assets/languages/ar.svg'

export const arLang: LangPack = {
    lang: Lang.AR,
    langName: "العربية",
    langShort: "ar",
    langIconUrl: icon,
    direction: "rtl",
    pack: {
        common: {
            noBTN: "لا",
            yesBTN: "نعم",
            errorCodeTitle: "رمز الخطأ",
            pageSizeSwitcherLabel: "الصفوف في الصفحة",
            ageConfirmationMessage: "هل تؤكد أنك تبلغ من العمر 18 عامًا؟",
            iConfirmBTN: "أؤكد",
            viewAllBTN: "عرض كل النتيجة",
            searchPlaceholder: "بحث",
            loadMore: "تحميل المزيد"
        },
        internalErrorMessages: {
            networkError: "حدث خطأ في الشبكة أثناء تنفيذ الطلب.",
            error502: "حدث خطأ في الخادم 502 أثناء تنفيذ الطلب."
        },
        errorMessages: {
            2: "لا توجد إدخالات.",
            3: "عنوان البريد الإلكتروني موجود بالفعل.",
            4: "رقم هاتف المستخدم موجود بالفعل.",
            5: "المستخدم مسجل الدخول بالفعل.",
            6: "كلمة المرور خاطئة.",
            7: "نتيجة الألعاب فارغة.",
            8: "معرف النتيجة لا يتطابق مع معرف الطلب.",
            9: "خطأ في طلب النتيجة.",
            10: "لا يوجد معرف جلسة في الاستجابة.",
            11: "المستخدم موجود بالفعل.",
            12: "الرمز المميز غير صالح. الرجاء تسجيل الدخول مرة أخرى.",
            13: "ليس لديك صلاحيات كافية لتنفيذ هذا الإجراء.",
            14: "معرف المستخدم مفقود لهذا الإجراء.",
            15: "المبلغ المالي مفقود.",
            16: "الإجراء غير موجود.",
            17: "الرمز المميز مفقود.",
            18: "المال غير كافٍ.",
            19: "يجب أن تكون والد هذا المستخدم لتنفيذ هذا الإجراء.",
            20: "المستخدم غير موجود.",
            21: "نوع القيمة لهذا الحقل خاطئ.",
            22: "كلمة المرور مفقودة.",
            23: "اسم المستخدم مفقود.",
            24: "معرف اللعبة مفقود.",
            25: "كلمة المرور الجديدة مفقودة.",
            26: "تعذر إعداد الرمز المميز.",
            27: "تعذر حذف الرمز المميز.",
            28: "معرف المستخدم مفقود.",
            29: "يجب أن يكون نوع القيمة لهذا الحقل تاريخ ووقت بتنسيق ISO.",
            30: "تعذر تحديث حظر المستخدم.",
            31: "يجب أن يكون هذا المستخدم في شجرتك لتنفيذ هذا الإجراء.",
            32: "هذا المستخدم محظور ولا يمكنه تنفيذ أي إجراءات.",
            33: "العملة مفقودة.",
            34: "القيمة القصوى مفقودة.",
            35: "يجب أن يكون نوع الحد مرة واحدة أو يومي أو أسبوعي أو شهري.",
            36: "نوع الحد مفقود.",
            37: "المزود غير موجود.",
            38: "مبلغ شرط المستوى مفقود.",
            39: "نوع شرط المستوى مفقود.",
            40: "عنوان المستوى مفقود.",
            41: "الاسترداد النقدي لهذا المستوى موجود بالفعل.",
            42: "الاسترداد النقدي لهذا المستوى غير موجود.",
            43: "المكافأة لهذا الرمز الترويجي موجودة بالفعل.",
            44: "المكافأة غير موجودة.",
            45: "الرمز الترويجي مفقود.",
            46: "وقت البدء مفقود.",
            47: "شرط مبلغ الإيداع مفقود.",
            48: "الرهان مفقود.",
            49: "أيام صلاحية المبلغ مفقودة.",
            50: "المعرف مفقود.",
            51: "نوع الاسترداد النقدي مفقود، يجب أن يكون \"رهان\" أو \"إيداع\".",
            52: "معدل الاسترداد النقدي مفقود.",
            53: "هذه العملة غير متوفرة.",
            54: "معلمة الحظر مفقودة - صح أو خطأ.",
            55: "مجموعة البنك لهذه العملة غير صالحة.",
            56: "اسم طريقة الدفع مفقود.",
            57: "هذا المستخدم أضاف بالفعل حسابًا لنظام الدفع هذا.",
            58: "طريقة الدفع هذه غير موجودة.",
            59: "طريقة الدفع هذه لا تدعم عملة هذا المستخدم.",
            60: "هذا المستخدم ليس لديه حساب موجود لنظام الدفع هذا.",
            61: "والد هذا المستخدم ليس لديه حساب موجود لنظام الدفع هذا.",
            62: "معرف التحويل مفقود.",
            63: "التحويل بهذا المعرف غير موجود.",
            64: "هذا التحويل مطلوب من قبل والد مختلف.",
            65: "لا يزال لديك مكافأة لم تنتهِ صلاحيتها.",
            66: "لم يبدأ إجراء المكافأة الترويجي هذا بعد.",
            67: "إجراء المكافأة الترويجي هذا انتهى بالفعل.",
            68: "هذا الإيداع لا يتناسب مع متطلبات المكافأة.",
            69: "هذا التحويل ليس معلقًا ولا يمكن الموافقة عليه أو رفضه.",
            70: "علامة الموافقة (صح/خطأ) مفقودة.",
            71: "يوم الأسبوع خاطئ لهذه المكافأة.",
            72: "عدد إيداعاتك لا يتناسب مع متطلبات المكافأة.",
            73: "المعلمات الإلزامية لطريقة الدفع هذه مفقودة أو لا تتطابق مع تنسيق الإثبات.",
            74: "معدل الإيداع مفقود.",
            75: "دور المستخدم مفقود.",
            76: "لا يمكنك إنشاء مستخدم بهذا الدور.",
            77: "معلمة التفعيل الترفيهي مفقودة.",
            78: "هذه الوظيفة غير متوفرة لهذا الموقع.",
            79: "كمية العملات المعدنية مفقودة.",
            80: "سعر حزمة العملات المعدنية مفقود.",
            81: "نوع المزود مفقود.",
            82: "خطأ في طلب النتيجة.",
            83: "اسم المزود مفقود.",
            84: "اسم المتعاقد مفقود.",
            85: "المتعاقد غير موجود.",
            86: "حزمة المكافآت مفقودة.",
            87: "معلمات الإثبات مفقودة.",
            88: "حزمة المكافآت غير موجودة.",
            89: "هذا المستخدم لا يمكنه إنشاء وكلاء.",
            90: "هذا المستخدم معطل للإيداع.",
            91: "هذا المستخدم معطل للسحب.",
            92: "نوع (أنواع) المعاملة مفقود.",
            93: "لم يتم العثور على مستخدمين.",
            94: "الرتب مفقودة.",
            95: "لا يوجد مزود بهذه الرتبة.",
            96: "لا يمكن تطبيق هذا الإجراء على المزودين من أنواع مختلفة.",
            97: "لا توجد لعبة بهذه الرتبة.",
            98: "لا يمكن تطبيق هذا الإجراء على الألعاب من مزودين مختلفين.",
            99: "لا يمكن تطبيق هذا الإجراء على الألعاب من متعاقدين مختلفين.",
            100: "يمكن أن يحتوي اسم المستخدم على أحرف لاتينية وأرقام و\".\" و\"_\" فقط وأن يتراوح طوله بين 5 و18 حرفًا.",
            101: "يمكن أن تحتوي كلمة مرور المستخدم على أحرف لاتينية وأرقام والرموز الخاصة التالية \"_\" و\".\" و\"?\" و\"!\" وأن يتراوح طولها بين 5 و18 حرفًا.",
            102: "يجب أن يكون شرط الرهان و/أو الإيداع أكبر من 0.",
            103: "هذه اللعبة غير موجودة أو تم إيقافها.",
            104: "خطأ في طلب Gitslotpark.",
            105: "تعذر تبديل الرصيد الترفيهي.",
            106: "تعذر حفظ إدخال تحويل الأموال في قاعدة البيانات.",
            107: "يمكنك إنشاء مستخدمين لنفسك أو لوكلائك فقط.",
            108: "خطأ في إنشاء رمز Evolution المميز.",
            109: "أدوار المستخدم للوالد والطفل لا تسمح بالإيداع.",
            110: "أدوار المستخدم للوالد والطفل لا تسمح بالسحب.",
            111: "تعذر إنشاء رمز Ezugi المميز.",
            112: "تعذر إنشاء رمز Relum المميز.",
            113: "لم ينتهِ وقت التهدئة للعجلة.",
            114: "الحزمة المجانية غير متوفرة (حتى الآن).",
            115: "تعذر إنشاء رمز Amigo المميز."
        },
        userInfoForm: {
            emailIsRequiredMess: 'البريد الإلكتروني مطلوب',
            notValidEmail: 'أدخل عنوان بريد إلكتروني صالح',
            currentPassFieldLabel: "كلمة المرور الحالية",
            emailFieldLabel: "البريد الإلكتروني",
            emptyFieldWarningMessage: "لا يجب أن يكون هذا الحقل فارغًا",
            firstnameFieldLabel: "الاسم الأول",
            lastnameFieldLabel: "اسم العائلة",
            newPassFieldLabel: "كلمة المرور الجديدة",
            passNotMatchWarningMessage: "كلمة المرور الجديدة وإعادة كتابة كلمة المرور لا تتطابقان",
            phoneFieldLabel: "الهاتف",
            retypePassFieldLabel: "أعد كتابة كلمة المرور الجديدة",
            saveInfoBTN: "حفظ المعلومات",
            updatePassBTN: "تحديث كلمة المرور",
            usernameLabel: "اسم المستخدم"
        },
        userInfo: {
            userInfoSection: 'معلومات المستخدم',
            changePasswordSection: 'تغيير كلمة المرور'
        },
        transactionsTable: {
            amountTableHeader: "المبلغ",
            methodTableHeader: "الطريقة",
            statusTableHeader: "الحالة",
            depositWithdrawTableHeader: "إيداع/سحب",
            dateTimeTableHeader: "التاريخ/الوقت"
        },
        gamesHistory: {
            providerTableHeader: 'المزوّد',
            gameTableHeader: 'اللعبة',
            betAmountTableHeader: 'مبلغ الرهان',
            winAmountTableHeader: 'مبلغ الفوز',
            dateTimeTableHeader: 'التاريخ/الوقت'
        },
        authorization: {
            loginTitle: "تسجيل الدخول",
            loginBTN: "تسجيل الدخول",
            usernameLabel: "اسم المستخدم",
            passwordLabel: "كلمة المرور",
            confirmLogOutMessage: "هل أنت متأكد أنك تريد تسجيل الخروج؟"
        },
        navigation: {
            home: "الرئيسية",
            sports: "الرياضة",
            liveSports: "الرياضة المباشرة",
            casino: "كازينو",
            liveCasino: "كازينو مباشر",
            virtuals: "الافتراضي",
            userInfo: "معلومات المستخدم",
            transactions: "المعاملات",
            gamesHistory: "تاريخ الألعاب"
        },
        footer: {
            playResponsibly: "العب بمسؤولية",
            ageRestriction: "للتسجيل في هذا الموقع، يُطلب من المستخدم قبول الشروط والأحكام العامة. في حالة تحديث الشروط والأحكام العامة، يمكن للمستخدمين الحاليين اختيار التوقف عن استخدام المنتجات والخدمات قبل أن تصبح هذه التحديثات سارية المفعول، والتي تكون بعد فترة لا تقل عن أسبوعين من الإعلان عنها.",
            disclaimer: "جميع منتجات www.uibet.org تُشغل بواسطة uibet. الحد الأقصى للأرباح هو 100,000 دينار تونسي لجميع المنتجات في شهر واحد، مع تطبيق الشروط والأحكام.",
            footerColumns: {
                casino: {
                    title: "كازينو",
                    featured: "المميزة",
                    new: "الجديد",
                    slots: "ماكينات القمار",
                    crash: "كراش",
                    pragmatic: "Pragmatic",
                    providers: "المزودون",
                    gameShows: "عروض الألعاب",
                    roulette: "الروليت",
                    blackjack: "بلاك جاك",
                    tableGames: "ألعاب الطاولة",
                    otherGames: "ألعاب أخرى"
                },
                sports: {
                    title: "الرياضة",
                    preliveEvents: "الأحداث قبل البث",
                    liveEvents: "الأحداث المباشرة",
                    football: "كرة القدم",
                    basketball: "كرة السلة",
                    tennis: "التنس"
                },
                legal: {
                    title: "القانوني",
                    termsNConditions: "الشروط والأحكام",
                    privacyPolicy: "سياسة الخصوصية",
                    responsibleGaming: "اللعب المسؤول"
                },
                help: {
                    title: "المساعدة",
                    aboutUs: "من نحن"
                },
                contactUs: {
                    title: "اتصل بنا"
                }
            }
        },
        topLinks: {
            sports: "الرياضة",
            liveSports: "الرياضة المباشرة",
            casino: "كازينو",
            liveCasino: "كازينو مباشر",
            pragmatic: "Pragmatic",
            evolution: "Evolution"
        },
        subcategories: {
            featured: 'مميز',
            new: 'جديد',
            slots: 'ماكينات القمار',
            crash: 'تحطم',
            pragmatic: 'براغماتي',
            providers: 'المزودون',
            liveCasino: 'كازينو مباشر',
            gameShows: 'عروض الألعاب',
            roulette: 'روليت',
            blackjack: 'بلاك جاك',
            tableGames: 'ألعاب الطاولة',
            otherGames: 'ألعاب أخرى',
            virtuals: 'ألعاب افتراضية',
            unknownGameType: 'نوع اللعبة غير معروف',
            provider: 'المزود',
        },
        casino: {
            showedOf: '%%b%% من %%a%% تم عرض',
        },
        searchWindow: {
            title: 'محرك البحث',
            searchPlaceholder: 'اكتب للبحث (لعبة | مزود)',
            searchHelper: 'البحث يتطلب 3 أحرف على الأقل',
            foundProviders: 'المزودون',
            foundGames: 'الألعاب'
        },
        cashback: {
            title: 'استرداد النقود لك!',
            gotItBTN: 'تم',
        }
    }
}