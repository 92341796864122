import {useEffect, useState} from "react"
import {useUser} from "./useUser";

export const useUserInit = () => {
    const { token, user, refreshUserBalance } = useUser()
    const [justMounted, setJustMounted] = useState(true)

    useEffect(() => {
        if (justMounted && user && token) {
            refreshUserBalance()
            setJustMounted(false)
        }
    }, [justMounted, refreshUserBalance, token, user])
}