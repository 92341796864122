import React, {FC, useEffect, useRef, useState} from "react";
import {Page} from "../components/layout/Page";
import Featured from "../../assets/subcategory_icons/featured.svg";
import {websiteRoutes} from "../features/routes/routes";
import {CasinoGrid} from "../components/casino/casino_grid/CasinoGrid";
import {Box, Button, Stack} from "@mui/material";
import {GameFrame} from "../components/game_launcher/GameFrame";
import {usePlayGames} from "../features/play_games/usePlayGames";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch} from "../store";
import {setGameSession} from "../features/play_games/playGamesSlice";
import {Close} from "@mui/icons-material";
import {useUser} from "../features/user/useUser";
import {LoginFormModal} from "../components/authorization/login/LoginForm";

export const GamePage: FC = () => {
    const {user, refreshUserBalance} = useUser()
    const [justMounted, setJustMounted] = useState<boolean>(true)
    const { startGameSession, gameSession } = usePlayGames()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const internalId = queryParams.get('internalId')
    const dispatch = useAppDispatch()
    const internalIdPrev = useRef<string|undefined|null>()
    const navigate = useNavigate();

    useEffect(() => {
        if (justMounted) {
            setJustMounted(false)
            if (gameSession.SessionUrl !== null) {
                dispatch(setGameSession({SessionId: null, SessionUrl: null}))
            }
        }
    }, [dispatch, gameSession.SessionUrl, justMounted]);

    useEffect(() => {
        if (internalId !== internalIdPrev.current) {
            internalIdPrev.current = internalId
            dispatch(setGameSession({SessionId: null, SessionUrl: null}))
            if (internalId !== null) {
                startGameSession(parseInt(internalId))
            }
        }
    }, [dispatch, internalId, startGameSession]);

    const goBackOrHome = () => {
        refreshUserBalance()
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };
    
    return <Page>
        <Stack>
            <Box display={'flex'} justifyContent={'end'}>
                <Button
                    onClick={goBackOrHome}
                    sx={{
                        mr: 2,
                        minWidth: '36px',
                        maxWidth: '36px',
                        height: '36px',
                        justifyContent: 'center',
                        display: 'flex',
                        backgroundColor: 'white',
                    }}
                    variant={'outlined'}
                >
                    <Close/>
                </Button>
            </Box>
            <Box
                width={'100%'}
                height={'85dvh'}
            >
                <GameFrame/>
            </Box>
            <Stack
                maxWidth={'1280px'}
                width={'100%'}
                mx={'auto'}
                p={2}
                gap={2}
            >
                <CasinoGrid
                    mode={'featured'}
                    picUrl={Featured}
                    title={'Featured'}
                    viewAllLink={websiteRoutes.featured}
                />
            </Stack>
        </Stack>

        {user === null && <LoginFormModal/>}
    </Page>
}